<template>
  <div class="layoutBorder">
    <div class="layoutHeader">
      <img svg-inline src="@/assets/vector/magnitDelivery.svg/">
    </div>
    <div class="layoutBody">
      <div>
        <h2>ваш персональный промокод</h2>
        <h1>AUTUMN60440</h1>
      </div>

      <span>Скидка 25% на первый заказ от 1000 рублей в<br>
        продуктах(только экспресс) и косметике, НЕ<br>
        действует на красные ценники, скидка не<br>
        более 4000 рублей до 31.10.2023</span>
    </div>

    <div class="layoutFooter">
      <button v-on:click="toShop">За покупками!</button>
    </div>


  </div>
</template>

<script>
export default {
  name: "promoMagnit",
  methods: {
    toShop() {
      window.open( 'https://magnitdeli.prfl.me/telegram/0a0986ab4c7d?erid=2VtzqxCqt4g', '_blank' )
    }
  },
}
</script>

<style scoped>
img { width: 58.82vw }
.layoutBody { text-align: center }
span { font-weight: lighter }
.layoutFooter { display: flex; justify-content: center }
h2 { font-weight: normal; font-size: 4.88vw }
h1 { margin-bottom: 13vw }
span { font-size: 5vw; line-height: normal }

@media screen and (min-width: 760px) {
  img { width: 27.5vw }
  .layoutHeader { display: flex; justify-content: center }
  h2 { font-size: 2.42vw }
  h1 { margin-bottom: 2.42vw }
  span { font-size: 3vw }
}
@media screen and ( min-width: 1240px ) {
  img { width: 400px }
  h2 { font-size: 32px }
  h1 { margin-bottom: 52px }
  span { font-size: 34px }

}
</style>